.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.light {
  font-weight: 300;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}
@media only screen and (max-width: 520px) {
  .br {
    display: none;
  }
  .buttonContainer {
    gap: 12px;
  }
}
