.text {
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
}
.primaryDefault {
  color: var(--Primary-Default);
}
.base0 {
  color: var(--Base-0);
}
.base500 {
  color: var(--Base-500);
}
.base400 {
  color: var(--Base-400);
}
.base600 {
  color: var(--Base-600);
}
.base800 {
  color: var(--Base-800);
}
.base950 {
  color: var(--Base-950);
}
.sm {
  font-size: 14px;
}
.base {
  font-size: 16px;
}
.lg {
  font-size: 18px;
}
.xl {
  font-size: 20px;
}
.xl2 {
  font-size: 24px;
}
.xl3 {
  font-size: 30px;
}
.textCenter {
  text-align: center;
}
.bold {
  font-weight: 700;
}
@media only screen and (max-width: 1199px) {
  .xl2 {
    font-size: 22px;
  }
  .xl3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 991px) {
  .lg {
    font-size: 16px;
  }

  .xl {
    font-size: 18px;
  }
  .xl2 {
    font-size: 20px;
  }
  .xl3 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .xl {
    font-size: 14.347px;
  }
}
