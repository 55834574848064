.wrapper {
  padding: 80px 0;
}
.container {
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 411px;
  width: 100%;
}

.light {
  background: linear-gradient(
    114deg,
    #ffb415 0%,
    #3d41ff 22.24%,
    #e2556e 34.21%,
    #4447ff 59.87%,
    #f0516e 85.53%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
}
.buttonContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 20px;
}
.arrowImg {
  max-width: 152px;
}
.img {
  max-width: 340px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .arrowImg {
    max-width: 100px;
  }
  .img {
    max-width: 280px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    padding-top: 40px;
    flex-direction: column;
    gap: 30px;
  }
  .heading {
    text-align: center;
  }
  .info {
    text-align: center;
  }
  .arrowImg {
    display: none;
  }
  .img {
    max-width: 340px;
  }
  .buttonContainer {
    justify-content: center;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    padding-top: 30px;
  }
  .infoContainer {
    gap: 12px;
  }
  .buttonContainer {
    padding-top: 12px;
  }
}
