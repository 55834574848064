.img {
  max-width: 120px;
}
.infoWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 96px 128px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
}
.infoContainer:first-child::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: rgba(255, 255, 255, 0.3);
  right: calc(-64px);
  transform: translateX(-50%);
}
.infoContainer:last-child {
  grid-column: 1/-1;
}
.infoContainer:last-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  padding: 96px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.descriptionAndButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.button {
  margin-top: auto;
}
.allStatistics {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.statistics {
  padding: 94px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.statisticsHeading {
  font-size: 24px;
}
.light {
  font-weight: 400;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 48px 20px;

  width: 156px;
  border-radius: 24px;
  border: 2px solid var(--Base-950, #09090f);
  background: var(--Base-0, #fff);
}
.light {
  font-weight: 300;
}
@media only screen and (max-width: 1199px) {
  .infoWrapper {
    gap: 70px;
  }
  .infoContainer:first-child::before {
    right: calc(-35px);
  }
  .infoContainer:last-child {
    padding: 70px 0;
  }
}
@media only screen and (max-width: 767px) {
  .infoWrapper {
    grid-template-columns: 1fr;
    gap: 64px;
  }

  .infoContainer {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 64px;
  }
  .infoContainer::before {
    display: none;
  }
  .infoContainer:last-child {
    grid-template-columns: 1fr;
    gap: 24px;
    border-top: none;
    padding-top: 0;
  }
  .statistics {
    padding: 64px 0;
    gap: 32px;
  }
}
@media only screen and (max-width: 520px) {
  .allStatistics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }
  .box {
    width: auto;
  }
}
