.button {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.556%;
  letter-spacing: -0.2px;
  display: flex;
  padding: 4px 4px 4px 24px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  background: transparent;
  cursor: pointer;
}
.arrowContainer {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
}
.arrow {
  font-size: 24px;
}
.wFull {
  width: 100%;
}

@media only screen and (max-width: 520px) {
  .button {
    font-size: 16px;
  }
}
