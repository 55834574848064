@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Inter";
}
:root {
  --Base-0: #fff;
  --Base-200: #dadadb;
  --Base-400: #9d9d9f;
  --Base-500: #78787c;
  --Base-600: #5f5f63;
  --Base-800: #222227;
  --Base-900: #15151c;
  --Base-950: #09090f;
  --Primary-Default: #434aff;
  --Yellow: #f7bd14;
  --Base-50: #f6f7f9;
}

body {
  padding: 0;
  min-height: 100vh;

  overflow-x: hidden;
  scroll-behavior: smooth;
  background: var(--bg);
}

.mainPage {
}
@media only screen and (max-width: 991px) {
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}
button,
a {
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}
button:hover,
a:hover {
  opacity: 0.8;
  transform: translateY(-3px);
}
.container {
  max-width: 1199px;
  margin: 0 auto;
  width: 90%;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-bg-dark);
  border-radius: 4px;
}
.overflow {
  scrollbar-width: thin;
  scrollbar-color: var(--Primary) rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari browsers */
.overflow::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}
.overflow::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.overflow::-webkit-scrollbar-thumb {
  background: var(--Primary);
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
