.wrapper {
  background: url(../../../images/pattern.png) var(--Primary-Default);

  object-fit: cover;
  object-position: center;
}

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 100px;
  padding-top: 20px;
}
.navItemsAndImg {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
}
.navItems {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.navItem {
  color: var(--Base-0);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  position: relative;
  white-space: nowrap;
  opacity: 0.5;
  display: flex;
  padding: 6px 16px;
  padding-left: 32px;
  align-items: center;
  gap: 12px;
  border-radius: 36px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 0.5;
}
.activeNavItem {
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.navItem::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 6px;
  height: 6px;
  left: 18px;
  border-radius: 50%;
  background: var(--Base-0);
  transform: translate(-50%, -50%);
}
.overviewImg {
  max-width: 64px;
  width: 100%;
}
.rightSide {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  position: relative;
}
.arrowImg {
  display: none;
}
.heading {
  color: var(--Base-0, #fff);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--Base-950, #09090f);
  font-family: "Roboto Condensed";
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 104.167%;
  letter-spacing: -3.84px;
}
.subHeading {
  color: var(--Base-0, #fff);
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--Base-950, #09090f);
  font-family: "Roboto Condensed";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 111.111%;
}
.buildEvm {
  max-width: 128px;
}
.infoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.buttonContainer {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.box {
  display: flex;
  padding: 18px;

  align-items: flex-end;
  gap: 15px;
  border-radius: 24px;
  border: 2px solid #fff;
  background: linear-gradient(240deg, #ded4ff 0%, #fdd2ac 100%);
  height: 100%;
}
.ecosystemInfoContainer {
  flex-direction: column;
  gap: 15px;
}
.ecosystemInfo {
  display: flex;

  align-items: flex-end;
  gap: 20px;
}
.arrowContainer {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: var(--Base-0, #fff);
}
.arrow {
  font-size: 18px;
  color: var(--Base-950);
}
.logo {
  max-width: 24px;
  display: block;
  margin-bottom: auto;
  margin-left: auto;
}
.br {
  display: none;
}
.label {
  font-size: 20px;
}
@media only screen and (max-width: 1199px) {
  .container {
    gap: 60px;
    padding-top: 0px;
  }
  .infoContainer {
    grid-template-columns: 1fr;
    gap: 18px;
  }
  .buttonContainer {
    max-width: 500px;
  }
  .subHeading {
    font-size: 30px;
  }
  .heading {
    font-size: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .container {
    gap: 30px;
    grid-template-columns: 1fr;
    padding-top: 50px;
  }
  .navItemsAndImg {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .overviewImg {
    max-width: 40px;
  }
  .rightSide {
    order: -1;
  }
  .heading {
    font-size: 70px;
  }
  .subHeading {
    font-size: 24px;
  }
  .text {
    max-width: 600px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .container {
  }
  .headingContainer {
    flex-direction: column;
    align-items: flex-start;
    max-width: 450px;
  }
  .heading {
    font-size: 60px;
    letter-spacing: -1.92px;
  }
  .subHeading {
    font-size: 20px;
  }
  .arrowImg {
    display: block;
    position: absolute;
    right: 0%;
    bottom: 0%;
    transform: translate(0, -50%) rotate(120deg);

    max-width: 120px;
    margin-left: auto;
  }
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 48px;
  }
  .subHeading {
    font-size: 18px;
  }
  .buttonContainer {
    grid-template-columns: 1fr;
  }
  .box,
  .ecosystemInfo {
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
  }
  .box {
    min-height: 90px;
  }
  .ecosystemInfo {
    margin-top: auto;
  }
  .logo {
    display: none;
  }
  .br {
    display: block;
  }
}
